import styled from "styled-components";

export const IconWrapper = styled.div`
  width: ${props => props.width ? props.width : "40px"};
  height: ${props => props.height ? props.height : "40px"};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${props => (props.isActive && props.bgColor) ? props.bgColor : "none"};

  :hover {
    background-color: ${props => props.bgColor ? props.bgColor : "#f2f2f2"};
  }
`;

export const MenuAppWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 100px);
  width: 330px;
  padding: 15px;
  border: 1px solid #ddd;
  min-height: 230px;
  max-height: 432px;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  left: ${props => props.left ? props.left : "auto"};
  right: ${props => props.right ? props.right : "auto"};
  top: ${props => props.top ? props.top : "auto"};
  bottom: ${props => props.bottom ? props.bottom : "auto"};
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px #ddd;
  z-index: 100000000;

  > div {
    height: 100px;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    box-sizing: border-box;

    img {
      width: 50px;
      margin-bottom: 8px !important;
      margin: auto;
    }

    p {
      margin: 0px;
    }

    :hover {
      background-color: #e3f2fd;
    }
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: #dcdcdc;
    outline: 1px solid #dcdcdc;
  }
`;