import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import AppIcon from "./app.png";
import DotIcon from "./dotIcon";
import { IconWrapper, MenuAppWrapper } from "./styled";

const useOutsideWatch = (ref, setOpen) => {
  useEffect(() => {
    const clickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", clickOutside);
    return () => {
      document.removeEventListener("mousedown", clickOutside);
    }
  }, [ref])
}

const AppDrawer = ({
  apps,
  token,
  orgId,
  width,
  height,
  popupLeft,
  popupRight,
  popupTop,
  popupBottom,
  bgColor,
  ...props
}) => {
    const wrapperRef = useRef(null);
    const [open, setOpen] = useState(false);
    const onRedirectApp = (app) => {
      window.open(`${app.link}/middlepage?token=${token}&organizationId=${orgId}`, "_blank");
    }

    useOutsideWatch(wrapperRef, setOpen);

    return (
      <React.Fragment>
        <IconWrapper
          width={width} 
          height={height}
          bgColor={bgColor}
          isActive={open === true}
          onClick={() => setOpen(!open)}
          {...props}
        >
          <DotIcon />
        </IconWrapper>

        {
          open && (
            <MenuAppWrapper
              ref={wrapperRef}
              left={popupLeft}
              right={popupRight}
              top={popupTop}
              bottom={popupBottom}
            >
              {
                apps.map((app, index) => (
                  <div key={index} onClick={() => onRedirectApp(app)}>
                    <img alt="app-icon" src={app.image ? app.image : AppIcon} />
                    <p>{app.label}</p>
                  </div>
                ))
              }
            </MenuAppWrapper>
          )
        }
      </React.Fragment>
  	);
};

AppDrawer.propTypes = {
  apps: PropTypes.array,
  token: PropTypes.string,
  orgId: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  popupLeft: PropTypes.string,
  popupRight: PropTypes.string,
  popupTop: PropTypes.string,
  popupBottom: PropTypes.string,
  bgColor: PropTypes.string,
};
  
AppDrawer.defaultProps = {
  apps: [
    {
      label: "Google",
      link: "#",
    },
    {
      label: "Google",
      link: "#",
    },
    {
      label: "Google",
      link: "#",
    },
    {
      label: "Google",
      link: "#",
    },
    {
      label: "Google",
      link: "#",
    }
  ],
  token: "",
  orgId: "",
  width: "40px",
  height: "40px",
  bgColor: "#ddd",
  popupLeft: "auto",
  popupRight: "auto",
  popupTop: "auto",
  popupBottom: "auto",
};

export default AppDrawer;